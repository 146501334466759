import { Component, createEffect, createResource, createSignal, For, Show } from "solid-js";
import AdminPanel from "../../../../components/AdminPanel";
import { fetchAPI, isPremium, NeedWebsocket, PremiumOnly } from "../../../../utils";
import AdminModal from "../../../../components/AdminModal";
import { useI18n } from "../../../../i18n";

const ServerPseudo: Component = () => {
  function getSelectorClassList(direction: string) {
    return !pseudoDirection.loading ? pseudoDirection().value === direction : false;
  }
  const { t } = useI18n();

  const [format, setFormat] = createSignal("");
  const [preview, setPreview] = createSignal("");

  createEffect(() => {
    const previewValue = format()
      .replace("{plyName}", "John Doe")
      .replace("{plySteamID64}", "76500000000000000")
      .replace("{rolePrefix}", "A")
      .replace("{roleName}", "Admin");
    setPreview(previewValue);
  });

  const [pseudoDirection, { mutate: mutatePseudoDirection }] = createResource("pseudoDirection", async () => {
    return fetchAPI("/users/:discordID/guilds/:guildID/servers/:serverID/settings/sync_pseudo_direction", "GET").then(
      async (res) => {
        if (!res.ok) throw new Error("An error occurred while fetching the pseudo direction.");
        return (await res.json()) || {};
      },
    );
  });

  function updateSyncPseudoDirection(direction: string) {
    fetchAPI("/users/:discordID/guilds/:guildID/servers/:serverID/settings/sync_pseudo_direction", "PUT", {
      value: direction,
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error("An error occurred while updating the pseudo direction.");
        }
      })
      .then((data) => {
        mutatePseudoDirection(data);
      });
  }

  const [pseudoFormat, { mutate: mutatePseudoFormat }] = createResource("pseudoFormat", async () => {
    return fetchAPI("/users/:discordID/guilds/:guildID/servers/:serverID/settings/pseudoFormat", "GET")
      .then(async (res) => {
        if (!res.ok) throw new Error("An error occurred while fetching the pseudo format.");
        return (await res.json()) || { value: "" };
      })
      .then((data) => {
        setFormat(data.value);
        return data;
      });
  });

  function updatePseudoFormat(format: string) {
    fetchAPI("/users/:discordID/guilds/:guildID/servers/:serverID/settings/pseudoFormat", "PUT", {
      value: format,
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error("An error occurred while updating the pseudo format.");
        }
      })
      .then((data) => {
        mutatePseudoFormat(data);
      });
  }

  const [activeRole, setActiveRole] = createSignal({ id: 0, enabled: false, role: "", name: "", prefix: "" });

  const [pseudoRoles, { mutate: mutatePseudoRoles }] = createResource("pseudoRoles", async () => {
    return fetchAPI("/users/:discordID/guilds/:guildID/servers/:serverID/pseudo", "GET").then(async (res) => {
      if (!res.ok) throw new Error("An error occurred while fetching the pseudo roles.");
      return (await res.json()) || [];
    });
  });

  async function addRole() {
    fetchAPI(`/users/:discordID/guilds/:guildID/servers/:serverID/pseudo`, "POST", activeRole())
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error("An error occurred while adding the role.");
        }
      })
      .then((data) => {
        mutatePseudoRoles((prev) => [...prev, data]);
      });
  }

  async function editRole() {
    fetchAPI(`/users/:discordID/guilds/:guildID/servers/:serverID/pseudo/${activeRole().id}`, "PUT", activeRole())
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error("An error occurred while editing the role.");
        }
      })
      .then((data) => {
        mutatePseudoRoles((prev) => prev.map((r) => (r.id === data.id ? data : r)));
      });
  }

  async function deleteRole(id: number) {
    fetchAPI(`/users/:discordID/guilds/:guildID/servers/:serverID/pseudo/${id}`, "DELETE")
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error("An error occurred while deleting the role.");
        }
      })
      .then((data) => {
        mutatePseudoRoles((prev) => prev.filter((r) => r.id !== data.id));
      });
  }

  return (
    <>
      <NeedWebsocket />
      <AdminModal title={t("dashboard.server.pseudo.editRole", "Edit Role")} id="edit_role_modal">
        <div class="form-control">
          <label class="label">
            <span class="label-text">{t("dashboard.server.pseudo.userGroup", "User Group")}</span>
          </label>
          <input
            type="text"
            class="input input-bordered"
            value={activeRole().role}
            onInput={(e) => {
              activeRole().role = e.currentTarget.value;
            }}
          />
        </div>
        <div class="form-control">
          <label class="label">
            <span class="label-text">{t("dashboard.server.pseudo.name", "Name")}</span>
          </label>
          <input
            type="text"
            class="input input-bordered"
            value={activeRole().name}
            onInput={(e) => {
              activeRole().name = e.currentTarget.value;
            }}
          />
        </div>
        <div class="form-control">
          <label class="label">
            <span class="label-text">{t("dashboard.server.pseudo.prefix", "Prefix")}</span>
          </label>
          <input
            type="text"
            class="input input-bordered"
            value={activeRole().prefix}
            onInput={(e) => {
              activeRole().prefix = e.currentTarget.value;
            }}
          />
        </div>
        <div class="form-control">
          <label class="label">
            <span class="label-text">{t("dashboard.server.pseudo.active", "Active")}</span>
          </label>
          <select
            class="select select-bordered"
            value={activeRole().enabled ? "true" : "false"}
            onChange={(e) => {
              activeRole().enabled = e.currentTarget.value === "true";
            }}
          >
            <option value="true">{t("dashboard.server.pseudo.yes", "Yes")}</option>
            <option value="false">{t("dashboard.server.pseudo.no", "No")}</option>
          </select>
        </div>
        <button
          class="btn btn-primary mt-2"
          onclick="edit_role_modal.close()"
          onClick={async () => {
            await editRole();
          }}
        >
          {t("dashboard.server.pseudo.save", "Save")}
        </button>
      </AdminModal>

      <AdminModal title={t("dashboard.server.pseudo.editFormat", "Edit Format")} id="edit_format">
        <Show when={!pseudoFormat.loading}>
          <div class="form-control p-2">
            <h1 class="text-lg text-gray-300 font-bold">{t("dashboard.server.pseudo.variables", "Variables")}</h1>
            <ul class="text-gray-400 list-inside">
              <li>{`{plyName} - ${t("dashboard.server.pseudo.playerName", "Player Name")}`}</li>
              <li>{`{plySteamID64} - ${t("dashboard.server.pseudo.playerSteamID64", "Player SteamID64")}`}</li>
              <li>{`{rolePrefix} - ${t("dashboard.server.pseudo.rolePrefix", "Role Prefix")}`}</li>
              <li>{`{roleName} - ${t("dashboard.server.pseudo.roleName", "Role Name")}`}</li>
            </ul>
          </div>
          <div class="form-control">
            <label class="label">
              <span class="label-text">{t("dashboard.server.pseudo.format", "Format")}</span>
            </label>
            <input
              type="text"
              class="input input-bordered"
              value={pseudoFormat().value}
              onInput={(e) => {
                pseudoFormat().value = e.currentTarget.value;
                setFormat(e.currentTarget.value);
              }}
            />
          </div>
          <div class="form-control">
            <label class="label">
              <span class="label-text">{t("dashboard.server.pseudo.preview", "Preview")}</span>
            </label>
            <input type="text" class="input input-bordered" value={preview()} readonly />
          </div>
          <button
            class="btn btn-primary mt-2"
            onclick="edit_server.close()"
            onClick={async () => {
              updatePseudoFormat(pseudoFormat().value);
            }}
          >
            {t("dashboard.server.pseudo.save", "Save")}
          </button>
        </Show>
      </AdminModal>

      <AdminPanel
        title={t("dashboard.server.pseudo.pseudo", "Pseudo")}
        description={t(
          "dashboard.server.pseudo.description",
          "Define the pseudo that are synchronized with your Discord server.",
        )}
        info={t("dashboard.server.pseudo.info", "Please note that Guild Owner will not have pseudo updates.")}
        premium={t("dashboard.server.pseudo.premium", "Synchronization limited to 'Gmod to Discord' for free users.")}
      >
        <div class="flex w-fit items-center">
          <span class="label-text mr-2 text-nowrap">
            {t("dashboard.server.pseudo.pseudoSyncDirection", "Pseudo Synchronization Direction")} :{" "}
          </span>
          <select
            class="select select-bordered w-full max-w-xs"
            onChange={(e) => {
              updateSyncPseudoDirection(e.currentTarget.value);
            }}
          >
            <option value="discord-to-gmod" selected={getSelectorClassList("discord-to-gmod")} disabled={!isPremium()}>
              {t("dashboard.server.pseudo.fromDiscordToGmod", "From Discord to Gmod")} <PremiumOnly />
            </option>
            <option value="gmod-to-discord" selected={getSelectorClassList("gmod-to-discord")}>
              {t("dashboard.server.pseudo.fromGmodToDiscord", "From Gmod to Discord")}
            </option>
            <option value="both" selected={getSelectorClassList("both")} disabled={!isPremium()}>
              {t("dashboard.server.pseudo.bothWays", "Both Ways")} <PremiumOnly />
            </option>
            <option value="disable" selected={getSelectorClassList("disable")}>
              {t("dashboard.server.pseudo.disable", "Disable")}
            </option>
          </select>
        </div>

        <div class="flex w-fit items-center">
          <span class="label-text mr-2">{t("dashboard.server.pseudo.format", "Format")}:</span>
          <input type="text" class="input input-bordered" value={preview()} readonly />
        </div>

        <div class="flex w-fit items-center gap-4">
          <button class="btn btn-primary" onclick="edit_format.showModal()">
            {t("dashboard.server.pseudo.editFormat", "Edit Format")}
          </button>
        </div>
      </AdminPanel>

      <AdminPanel
        title={t("dashboard.server.pseudo.rolePrefix", "Role Prefix")}
        description={t("dashboard.server.pseudo.rolePrefixDescription", "Define prefixes for each user group.")}
        type="none"
        premium={t("dashboard.server.pseudo.rolePrefixPremium", "Limited to 3 roles customization for free users.")}
      >
        <table class="table">
          <thead>
            <tr class="text-white text-l">
              <th>{t("dashboard.server.pseudo.userGroup", "User Group")}</th>
              <th>{t("dashboard.server.pseudo.name", "Name")}</th>
              <th>{t("dashboard.server.pseudo.prefix", "Prefix")}</th>
              <th class="w-1/6 text-center">{t("dashboard.server.pseudo.active", "Active")}</th>
              <th class="w-1/6 text-center">{t("dashboard.server.pseudo.actions", "Actions")}</th>
            </tr>
          </thead>
          <tbody>
            <Show
              when={!pseudoRoles.loading}
              fallback={
                <div class="flex justify-center h-36">
                  <div class="loading loading-spinner loading-lg"></div>
                </div>
              }
            >
              <For each={pseudoRoles()}>
                {(role) => (
                  <tr>
                    <td>{role.role}</td>
                    <td>{role.name}</td>
                    <td>{role.prefix}</td>
                    <td>
                      <div class="flex gap-2 justify-center">
                        {role.enabled ? <i class="fas fa-check text-green"></i> : <i class="fas fa-times text-red"></i>}
                      </div>
                    </td>
                    <td>
                      <div class="flex gap-2 justify-center">
                        <div class="tooltip tooltip-info" data-tip={t("dashboard.server.pseudo.edit", "Edit")}>
                          <i
                            class="hover:cursor-pointer fas fa-edit"
                            onclick="edit_role_modal.showModal()"
                            onClick={() => setActiveRole(role)}
                          ></i>
                        </div>
                        <div class="tooltip tooltip-error" data-tip={t("dashboard.server.pseudo.delete", "Delete")}>
                          <i class="hover:cursor-pointer fas fa-trash text-red" onClick={() => deleteRole(role.id)}></i>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </For>
            </Show>
          </tbody>
        </table>

        <div class="flex gap-4 p-4">
          <button
            class="btn btn-primary"
            onClick={addRole}
            disabled={!isPremium() && (!pseudoRoles() || pseudoRoles().length >= 3)}
          >
            {t("dashboard.server.pseudo.addRole", "Add Role")}
          </button>
        </div>
      </AdminPanel>
    </>
  );
};

export default ServerPseudo;
