import { Component, For } from "solid-js";
import { A } from "@solidjs/router";
import logo from "../assets/brand/logo.png";
import { useI18n } from "../i18n";

export const Footer: Component = () => {
  const { t } = useI18n();

  const footerLinks = [
    {
      title: t("main.services", "Services"),
      links: [
        {
          title: t("main.dashboard", "Dashboard"),
          href: "/dashboard",
        },
        {
          title: t("main.servers_ranking", "Servers Ranking"),
          href: "/servers",
        },
      ],
    },
    {
      title: t("main.resources", "Resources"),
      links: [
        {
          title: t("main.documentation", "Documentation"),
          href: "/docs",
        },
        {
          title: t("main.premium", "Premium"),
          href: "/premium",
        },
        {
          title: t("main.support", "Support"),
          href: "/support",
        },
      ],
    },
    {
      title: "Legal",
      links: [
        {
          title: t("main.terms", "Terms of Service"),
          href: "/legal/terms",
        },
        {
          title: t("main.privacy", "Privacy Policy"),
          href: "/legal/privacy",
        },
      ],
    },
  ];

  return (
    <>
      <div class="flex flex-shrink-0 flex-col">
        <hr class="border-[1px] border-primary" />
        <footer class="footer p-10 bg-background text-base-content max-w-screen-2xl mx-auto">
          <For each={footerLinks}>
            {({ title, links }) => (
              <nav>
                <h6 class="footer-title text-white opacity-100">{title}</h6>
                <For each={links}>
                  {({ title, href }) => (
                    <A class="link link-hover" href={href}>
                      {title}
                    </A>
                  )}
                </For>
              </nav>
            )}
          </For>
        </footer>
        <hr class="border-[1px] border-primary" />
        <footer class="footer px-10 py-4 bg-background text-base-content max-w-screen-2xl mx-auto">
          <aside class="items-center grid-flow-col">
            <img src={logo} alt="logo" width="40" height="40" />
            <div class="ml-2 flex flex-col">
              <h3 class="text-font font-bold">Gmod Integration</h3>
              <p>© 2024 Gmod Integration. {t("footer.all_rights_reserved", "All rights reserved.")}</p>
            </div>
          </aside>
        </footer>
      </div>
    </>
  );
};
