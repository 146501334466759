import { Component, createResource, createSignal, For, Show } from "solid-js";
import AdminPanel from "../../../../components/AdminPanel";
import { fetchAPI } from "../../../../utils";
import { useI18n } from "../../../../i18n";

const fetchErrors = async () => {
  const res = await fetchAPI("/users/:discordID/guilds/:guildID/servers/:serverID/errors", "GET");
  if (!res.ok) {
    return {};
  }
  return await res.json();
};

interface AddError {
  data: Object;
  addon: string;
  count: number;
  realm: string;
  title: string;
  id: string;
  createAt: Date;
}

const [selectError, setSelectError] = createSignal(0);
let idxLog = 0;
const AddErrorComponent: Component<AddError> = (props) => {
  const { t } = useI18n();

  if (props.data.stack) {
    props.data.stack = JSON.parse(props.data.stack);
  }
  const errorContentStr = JSON.stringify(props.data, null, 2).split("\n");
  idxLog++;
  const localIdxLog = idxLog;
  return (
    <>
      <tr>
        <td class="text-gray-300 text-nowrap">{props.createAt.toLocaleString()}</td>
        <td class="text-white text-nowrap">{props.addon}</td>
        <td class="text-white">{props.realm}</td>
        <td class="text-white">{props.count}</td>
        <td class="text-white break-words">{props.title}</td>
        <td>
          <div class="flex gap-2 justify-center">
            <div
              class="tooltip tooltip-info"
              data-tip={t("dashboard.server.errors.actions.view_details", "View Details")}
              onClick={() => {
                if (selectError() === localIdxLog) {
                  setSelectError(0);
                } else {
                  setSelectError(localIdxLog);
                }
              }}
            >
              <Show when={selectError() === localIdxLog}>
                <i class="fas fa-chevron-up"></i>
              </Show>
              <Show when={selectError() !== localIdxLog}>
                <i class="fas fa-chevron-down"></i>
              </Show>
            </div>
            <Show when={props.id !== "" && props.id !== "0"}>
              <div
                class="tooltip tooltip-info"
                data-tip={t("dashboard.server.errors.actions.open_workshop", "Open Workshop")}
              >
                <a href={`https://steamcommunity.com/sharedfiles/filedetails/?id=${props.id}`} target="_blank">
                  <i class="fas fa-external-link-alt"></i>
                </a>
              </div>
            </Show>
            <div class="tooltip tooltip-info" data-tip={t("dashboard.server.errors.actions.download", "Download")}>
              <a
                href={`data:text/plain;charset=utf-8,${encodeURIComponent(errorContentStr.join("\n"))}`}
                download={`log-${props.createAt.toLocaleString()}.json`}
              >
                <i class="fas fa-download"></i>
              </a>
            </div>
          </div>
        </td>
      </tr>

      <Show when={selectError() === localIdxLog}>
        <tr>
          <td colspan="6">
            <pre>
              <code>{errorContentStr.join("\n")}</code>
            </pre>
          </td>
        </tr>
      </Show>
    </>
  );
};

const ServerErrors: Component = () => {
  const [errors, { mutate: errorsMutate }] = createResource("errors", fetchErrors);
  const { t } = useI18n();

  return (
    <>
      <AdminPanel
        title={t("dashboard.server.errors.title", "Errors")}
        description={t("dashboard.server.errors.description", "Watch the errors of your server.")}
        type="none"
        premium={t("dashboard.server.errors.premium", "Limited to 50 last errors for free users.")}
      >
        <table class="table">
          <thead>
            <tr class="text-white text-l">
              <th>{t("dashboard.server.errors.error_details.timestamp", "Date")}</th>
              <th>{t("dashboard.server.errors.error_details.addon", "Addon")}</th>
              <th>{t("dashboard.server.errors.error_details.realm", "Realm")}</th>
              <th>{t("dashboard.server.errors.error_details.count", "Count")}</th>
              <th>{t("dashboard.server.errors.error_details.message", "Message")}</th>
              <th class="w-1/6 text-center">{t("dashboard.server.errors.actions.actions", "Actions")}</th>
            </tr>
          </thead>
          <tbody>
            <Show
              when={!errors.loading}
              fallback={
                <span class="loading loading-lg">{t("dashboard.server.errors.loading", "Loading errors...")}</span>
              }
            >
              <For each={errors()}>
                {(errorData) => (
                  <AddErrorComponent
                    count={errorData.count}
                    data={errorData}
                    addon={errorData.name}
                    id={errorData.workshopID}
                    realm={errorData.realm}
                    title={errorData.error}
                    createAt={new Date(errorData.createdAt)}
                  />
                )}
              </For>
            </Show>
          </tbody>
        </table>
      </AdminPanel>
    </>
  );
};

export default ServerErrors;
