import { Component, Show } from "solid-js";
import { isPremium, PremiumBadge } from "../utils";
import { A } from "@solidjs/router";
import { linkBadge } from "./DashboardMenu";
import { useI18n } from "../i18n";

interface AdminPanelProps {
  title: string;
  description: string;
  children?: any;
  type?: string;
  info?: string;
  badge?: linkBadge;
  premium?: string | boolean;
}

const AdminPanel: Component<AdminPanelProps> = (props) => {
  const { t } = useI18n();
  return (
    <>
      <div class="border border-primary rounded-lg flex flex-col">
        <div class="flex flex-col gap-4 p-4">
          <div class="flex gap-4 items-center">
            <h2 class="text-xl font-bold">{props.title}</h2>
            <Show when={props.badge}>
              <div class="badge badge-outline">{props.badge}</div>
            </Show>
            <Show when={props.premium && !isPremium()}>
              <PremiumBadge />
            </Show>
          </div>
          <Show when={props.premium && !isPremium()}>
            <p class="text-amber-400">
              {props.premium === true
                ? t("main.buy_premium", "This feature is only available to premium users.")
                : props.premium}{" "}
              <A class="link" href="/premium">
                {t("tools.upgrade_now", "Upgrade Now")}
              </A>
            </p>
          </Show>
          <p class="text-gray-400">{props.description}</p>
          <Show when={props.info}>
            <div class="flex gap-2 items-center text-gray-500">
              <i class="fa-solid fa-info-circle"></i>
              <span>{props.info}</span>
            </div>
          </Show>
        </div>

        <hr class="border-primary" />

        <div
          class="flex flex-col"
          classList={{
            "gap-4": !props.type || props.type != "none",
            "p-4": !props.type || props.type != "none",
          }}
        >
          {props.children}
        </div>
      </div>
    </>
  );
};

export default AdminPanel;
