import { Component } from "solid-js";

interface DiscordRoleProps {
  role: {
    id: string;
    name: string;
    color: number;
    colorHex: string;
  };
}

const DiscordRole: Component<DiscordRoleProps> = (props) => {
  if (props.role.color === 0) {
    props.role.colorHex = "#7d95ff";
  }
  return (
    <>
      <p
        class="w-min text-nowrap rounded-md px-1"
        style={{ color: props.role.colorHex, "background-color": `hsl(${props.role.color}, 25%, 10%, 0.4)` }}
      >{`@${props.role.name}`}</p>
    </>
  );
};

export default DiscordRole;
