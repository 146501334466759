import { Component, createResource, createSignal, For, Show } from "solid-js";
import { fetchAPI, getAPIUrl } from "../../../../utils";
import AdminPanel from "../../../../components/AdminPanel";
import { useI18n } from "../../../../i18n";

enum Status {
  OPEN = "open",
  CLOSE = "close",
}

enum Importance {
  LOW = "low",
  MEDIUM = "medium",
  HIGH = "high",
  TRIVIAL = "trivial",
  CRITICAL = "critical",
}

const importanceColor = {
  [Importance.LOW]: "bg-green-500",
  [Importance.MEDIUM]: "bg-yellow-500",
  [Importance.HIGH]: "bg-red-500",
  [Importance.TRIVIAL]: "bg-blue-500",
  [Importance.CRITICAL]: "bg-red-700",
};

interface AddReportBugs {
  steamID64: string;
  description: string;
  status: Status;
  importance: Importance;
  actual: string;
  expected: string;
  screenshot: string;
  steps: string;
  id: string;
  createAt: Date;
}

const [selectReport, setSelectReport] = createSignal(0);
const [hasLoadScreenshot, setHasLoadScreenshot] = createSignal(false);
let idxLog = 0;
const AddReportBugsComponent: Component<AddReportBugs> = (props) => {
  idxLog++;
  const localIdxLog = idxLog;
  let shortDescription = props.description.substring(0, 160);
  if (props.description.length > 160) {
    shortDescription += "...";
  }
  const { t } = useI18n();
  return (
    <>
      <tr>
        <td class="w-1/6 text-gray-300 text-nowrap">{props.createAt.toLocaleString()}</td>
        <td class="w-1/6 text-white text-nowrap">{props.steamID64}</td>
        {/*<td class="w-1/12 text-white">{props.status}</td>*/}
        <td class="w-1/12 text-white">{props.importance}</td>
        <td class="text-white break-words">{shortDescription}</td>
        <td>
          <div class="flex gap-2 justify-center">
            <div
              class="tooltip tooltip-info"
              data-tip={t("dashboard.server.actions.showMore", "Show More")}
              onClick={() => {
                setHasLoadScreenshot(false);
                if (selectReport() === localIdxLog) {
                  setSelectReport(0);
                } else {
                  setSelectReport(localIdxLog);
                }
              }}
            >
              <Show when={selectReport() === localIdxLog}>
                <i class="fas fa-chevron-up"></i>
              </Show>
              <Show when={selectReport() !== localIdxLog}>
                <i class="fas fa-chevron-down"></i>
              </Show>
            </div>
          </div>
        </td>
      </tr>

      <Show when={selectReport() === localIdxLog}>
        <tr>
          <td colspan="6">
            <h3 class="font-bold">{t("dashboard.server.screenshot", "Screenshot")}</h3>
            <Show when={!hasLoadScreenshot()}>
              <span class="loading loading-lg"></span>
            </Show>
            <img
              class="w-1/2 h-1/2"
              src={`${getAPIUrl(false)}/screenshots/${props.screenshot}`}
              alt="screenshot"
              onLoad={() => setHasLoadScreenshot(true)}
              onError={() => setHasLoadScreenshot(false)}
              classList={{
                hidden: !hasLoadScreenshot(),
              }}
            />
            <br />
            <h3 class="font-bold">{t("dashboard.server.description", "Description")}</h3>
            <p>{props.description}</p>
            <br />
            <h3 class="font-bold">{t("dashboard.server.expected", "What did you expect to happen ?")}</h3>
            <p>{props.expected}</p>
            <br />
            <h3 class="font-bold">{t("dashboard.server.actual", "What actually happened ?")}</h3>
            <p>{props.actual}</p>
            <br />
            <h3 class="font-bold">{t("dashboard.server.steps", "Steps to Reproduce")}</h3>
            <p>{props.steps}</p>
          </td>
        </tr>
      </Show>
    </>
  );
};

const ServerBugs: Component = () => {
  const [bugsReport, { mutate: bugsReportMutate }] = createResource("bugsReport", async () => {
    return await fetchAPI("/users/:discordID/guilds/:guildID/servers/:serverID/bugs", "GET")
      .then(async (res) => {
        if (!res.ok) {
          return {};
        }
        return await res.json();
      })
      .then((data) => {
        return data.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
      });
  });

  const { t } = useI18n();

  return (
    <>
      <AdminPanel
        title={t("dashboard.server.bugsReport.title", "Bugs Report")}
        description={t("dashboard.server.bugsReport.description", "List of all bugs reported on your server")}
        type="none"
      >
        <table class="table">
          <thead>
            <tr class="text-white text-l">
              <th class="w-1/6">{t("dashboard.server.date", "Date")}</th>
              <th class="w-1/8">{t("dashboard.server.player", "Player")}</th>
              {/*<th class="w-1/8">{t("dashboard.server.status", "Status")}</th>*/}
              <th class="w-1/8">{t("dashboard.server.importance", "Importance")}</th>
              <th>{t("dashboard.server.description", "Description")}</th>
              <th class="w-1/6 text-center">{t("dashboard.server.actions", "Actions")}</th>
            </tr>
          </thead>
          <tbody>
            <Show when={!bugsReport.loading} fallback={<span class="loading loading-lg"></span>}>
              <For each={bugsReport()}>
                {(reportData) => (
                  <AddReportBugsComponent
                    status={reportData.status}
                    importance={reportData.importance}
                    description={reportData.description}
                    steamID64={reportData.steamID64}
                    actual={reportData.actual}
                    expected={reportData.expected}
                    screenshot={reportData.screenshot}
                    steps={reportData.steps}
                    id={reportData.id}
                    createAt={new Date(reportData.createdAt)}
                  />
                )}
              </For>
            </Show>
          </tbody>
        </table>
      </AdminPanel>
    </>
  );
};

export default ServerBugs;
