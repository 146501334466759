import { Component, createSignal } from "solid-js";
import { fetchAPI, getServer } from "../../../../utils";
import { useNavigate } from "@solidjs/router";
import AdminModal from "../../../../components/AdminModal";
import AdminPanel from "../../../../components/AdminPanel";
import defaultServer from "../../../../assets/defaultServer.png";
import { useI18n } from "../../../../i18n";

const ServerInformations: Component = () => {
  const { t } = useI18n();
  const navigate = useNavigate();
  const [server, setServer] = createSignal(getServer());
  const [showToken, setShowToken] = createSignal(false);

  const deleteServer = async () => {
    const res = await fetchAPI(`/users/:discordID/guilds/:guildID/servers/${server().id}`, "DELETE");
    if (!res.ok) {
      return;
    }
    navigate("/dashboard/guilds/:guildID/config/servers");
  };

  const editServer = async () => {
    const res = await fetchAPI(`/users/:discordID/guilds/:guildID/servers/${server().id}`, "PUT", server());
    if (!res.ok) {
      return;
    }
    const newServer = await res.json();
    setServer(newServer);
    localStorage.setItem("server", JSON.stringify(newServer));
  };

  const resetServerToken = async () => {
    const res = await fetchAPI(`/users/:discordID/guilds/:guildID/servers/${server().id}/token`, "POST");
    if (!res.ok) {
      return;
    }
    const newServer = await res.json();
    setServer(newServer);
    localStorage.setItem("server", JSON.stringify(newServer));
  };

  return (
    <>
      <AdminModal title={t("dashboard.server.server_informations.edit_server", "Edit Server")} id="edit_server">
        <div class="form-control">
          <label class="label">
            <span class="label-text">{t("dashboard.server.server_informations.server_name", "Name")}</span>
          </label>
          <input
            type="text"
            class="input input-bordered"
            value={server().name}
            onInput={(e) => {
              server().name = e.currentTarget.value;
            }}
          />
        </div>
        <div class="form-control">
          <label class="label">
            <span class="label-text">
              {t("dashboard.server.server_informations.server_description", "Description")}
            </span>
          </label>
          <input
            type="text"
            class="input input-bordered"
            value={server().description}
            onInput={(e) => {
              server().description = e.currentTarget.value;
            }}
          />
        </div>
        <div class="form-control">
          <label class="label">
            <span class="label-text">{t("dashboard.server.server_informations.server_image", "Image")}</span>
          </label>
          <input
            type="text"
            class="input input-bordered"
            value={server().image}
            onInput={(e) => {
              server().image = e.currentTarget.value;
            }}
          />
        </div>
        <div class="form-control">
          <label class="label">
            <span class="label-text">{t("dashboard.server.server_informations.server_ip", "IP")}</span>
          </label>
          <input
            type="text"
            class="input input-bordered"
            value={server().ip}
            onInput={(e) => {
              server().ip = e.currentTarget.value;
            }}
          />
        </div>
        <div class="form-control">
          <label class="label">
            <span class="label-text">{t("dashboard.server.server_informations.server_port", "Port")}</span>
          </label>
          <input
            type="text"
            class="input input-bordered"
            value={server().port}
            onInput={(e) => {
              server().port = e.currentTarget.value;
            }}
          />
        </div>
        <div class="form-control">
          <label class="label">
            <span class="label-text">{t("dashboard.server.server_informations.server_public", "Public")}</span>
          </label>
          <select
            class="select select-bordered"
            value={server().isPublic ? "true" : "false"}
            onChange={(e) => {
              server().isPublic = e.currentTarget.value === "true";
            }}
          >
            <option value="true">{t("dashboard.server.server_informations.yes", "Yes")}</option>
            <option value="false">{t("dashboard.server.server_informations.no", "No")}</option>
          </select>
        </div>
        <button
          class="btn btn-primary mt-2"
          onclick="edit_server.close()"
          onClick={async () => {
            await editServer();
          }}
        >
          {t("dashboard.server.server_informations.save_changes", "Save")}
        </button>
      </AdminModal>

      <AdminPanel
        title={t("dashboard.server.server_informations.informations", "Informations")}
        description={t(
          "dashboard.server.server_informations.informations_description",
          "Here you can see some informations about your server, and edit some of them.",
        )}
      >
        <div class="flex w-fit items-center">
          <span class="label-text mr-2">{t("dashboard.server.server_informations.server_name", "Name")}:</span>
          <span class="label-text">{server().name}</span>
        </div>

        <div class="flex w-fit items-center">
          <span class="label-text mr-2">
            {t("dashboard.server.server_informations.server_description", "Description")}:
          </span>
          <span class="label-text">{server().description}</span>
        </div>

        <div class="flex w-fit items-center">
          <span class="label-text mr-2">{t("dashboard.server.server_informations.server_logo", "Logo")}:</span>
          <img class="w-8 h-8 rounded object-cover" src={server().image || defaultServer} alt="Server Logo" />
        </div>

        <div class="flex w-fit items-center">
          <span class="label-text mr-2">{t("dashboard.server.server_informations.server_ip", "IP")}:</span>
          <span class="label-text">{server().ip + ":" + server().port}</span>
        </div>

        <div class="flex w-fit items-center">
          <span class="label-text mr-2">{t("dashboard.server.server_informations.server_id", "ID")}:</span>
          <span class="label-text">{server().id}</span>
        </div>

        <div class="flex w-fit items-center">
          <span class="label-text mr-2">{t("dashboard.server.server_informations.server_token", "Token")}:</span>
          <span class="label-text" classList={{ link: !showToken() }} onClick={() => setShowToken(true)}>
            {showToken() ? server().token : t("dashboard.server.server_informations.show_token", "Click to Show")}
          </span>
        </div>

        <div class="flex w-fit items-center">
          <span class="label-text mr-2">
            {t("dashboard.server.server_informations.server_public", "Server Public")}:
          </span>
          <span class="label-text">
            {server().isPublic
              ? t("dashboard.server.server_informations.yes", "Yes")
              : t("dashboard.server.server_informations.no", "No")}
          </span>
        </div>

        <div class="flex w-fit items-center gap-4">
          <button
            class="btn btn-warning"
            onClick={async () => {
              await deleteServer();
            }}
          >
            {t("dashboard.server.server_informations.delete_server", "Delete Server")}
          </button>

          <button
            class="btn btn-warning"
            onClick={async () => {
              await resetServerToken();
            }}
          >
            {t("dashboard.server.server_informations.reset_server_token", "Reset Server Token")}
          </button>

          <button class="btn btn-primary" onclick="edit_server.showModal()">
            {t("dashboard.server.server_informations.edit_server", "Edit Server")}
          </button>
        </div>
      </AdminPanel>
    </>
  );
};

export default ServerInformations;
